
import styled from 'styled-components';

export const StyledMain = styled.main`
    width: 100vw;
    text-align: center;
    /* padding-top: 50px; */
    padding-right: 20px;
    padding-left: 20px;
    margin: 0 auto;
.upload__container{
        max-width: 500px;
        width:100% ;
    background: #F2F4F8;
    border-radius: 20px;
    padding: 40px;
        margin: 0 auto;
    margin-top: 100px;
}
.upload__button{
      width: 100%;
    margin-top: 40px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid;
    border: none;
    background: #5B9D77;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 15%);
    border-radius: 15.5px;
    font-style: normal;
    font-weight: 500;
    margin-top: 20px;
    font-size: 23px;
    line-height: 27px;
    cursor: pointer;
    color: #FFFFFF;

    :disabled {
      opacity: .2;
      cursor:not-allowed ;
    }
}
input{
      display: none;
}
.upload__container__label{
        height: 100px;
    display: block;
    margin: 0 auto;
    background: #fff;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #39496A;

    :hover{
      border: 1px dotted #39496A;
    }
}
  .title__text{
        font-size: 30px;
    margin-bottom: 10px;
  }
.subtitle__text{
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
}

refresh{

}
.title{
/* margin-bottom:75px;   */
}
form{
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
      height: 25vh;
    align-items: center;
    flex-direction: column;
}
.form__input{
  width: 90%;
max-width: 600px;
height: 60px;
border:none;
background: #FFFFFF;
box-shadow: 0px 8px 16px #E0E2E4;
border-radius: 16px;
font-size: 21px;
padding: 0 34px;
color: rgba(0, 0, 0, 0.75072);
}
.form__search_button{
width: 153px;
height: 60px;
border: none;
background: #5B9D77;
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
border-radius: 15.5px;
font-style: normal;
font-weight: 500;
margin-top: 20px;
font-size: 23px;
line-height: 27px;
cursor:pointer;
:hover{
box-shadow: 0px 8px 16px #E0E2E4;
}
:active {
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
}

:disabled{
  opacity: .4;
}
/* identical to box height */


color: #FFFFFF;
}

@media screen and (min-width: 800px){
  /* margin-top: 256px */
}
`;
