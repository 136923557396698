import React, {  useContext } from 'react'
import { context } from '../../context'
import {
  StyledMain,
  StyledResult,StyledDetails
} from './Home.style.js'


export const Home = () => {
  let {
    infoText,
handleResendProfileCode,
    handleSendToTelegramAndMail,
  handleSearch,
phoneNumber,
handleChange,
isMailSending,
    isDataLoading,
    smsData,
searchData
  } = useContext(context)


  return (
    <StyledMain className="main">
      <StyledDetails>
        {(searchData && searchData.length > 0 )&& <h1>{searchData[0].msisdn}</h1>}

        <div className="display-data">
        {searchData?.length > 0 && [...searchData].map((data,index) => {
          return <ul key={`${data['sms-id']}_${index}`} className="card">
    <li className="card__item"><strong>Request :</strong> <p>{data.request}</p></li>
    <li className="card__item"><strong>Response :</strong><p>{data.response}</p></li>
   { data['response-err'] && <li className="card__item error"><strong>Response Error :</strong><p>{data['response-err']}</p></li>}
    <li className="card__item"><strong>Network :</strong><p>{data.network}</p></li>
    <li className="card__item"><strong>Time Requested :</strong><p>{data['time-req']}</p></li>
    <li className="card__item"><strong>Time Responded :</strong><p>{data['time-resp']}</p></li>
  </ul>
        })}
        {
searchData?.length === 0 && <p className="empty">Please advise user to retry as we do not have any record of transaction with user</p>
        }

        {
!searchData && <p className="empty">Please search for users phone number with format 08012345678, 7012345678 or 2347012345678</p>
          }
          </div>
      </StyledDetails>
<div className="search-container">
      <h1 className='title'>Candidate Query Portal</h1>

      <form className="form" onSubmit={handleSearch}>
        <input type="tel" value={phoneNumber} name="phoneNumber" className="form__input" onChange={handleChange} disabled={isMailSending || isDataLoading ? 1 : 0} placeholder="phone number"/>
        <button type="button" className="form__search_button" onClick={handleSearch} disabled={isMailSending || isDataLoading ? 1 : 0}>{isDataLoading? "Searching...":"Search"}</button>
      </form>

      <StyledResult>
        <p className="result__title">{infoText}</p>
        {
          infoText !== "" && smsData && smsData?.isError === false && <button className="result__action" onClick={handleResendProfileCode} disabled={isMailSending || isDataLoading ? 1 : 0}>
          {isMailSending ? "Sending" : "Resend Profile Code"}
          </button>
        }
        {
           infoText !== "" && smsData && smsData?.isError === true && <button className="result__action error" onClick={handleSendToTelegramAndMail} disabled={isMailSending || isDataLoading ? 1 : 0}>
          Escalate Request
          </button>
        }
      </StyledResult>
      </div>



    </StyledMain>
  );

}
