import React,{useState} from 'react'
import { useToasts } from 'react-toast-notifications'
import { StyledMain } from './UploadNIN.style'

export const UploadNIN = () => {
    const [selectedFile, setSelectedFile] = useState(null)
  const [status, setStatus] = useState("IDLE")
  const {addToast} = useToasts()

    const selectFile = (e) => {
      setSelectedFile(e.target.files[0])
    setStatus("IDLE")

    }

      // On file upload (click the upload button)
    const onFileUpload = async () => {
setStatus("LOADING")
      // Create an object of formData
      const formData = new FormData();

      // Update the formData object
      formData.append(
        "nin_data",
        selectedFile
      );

      // Details of the uploaded file
      console.log(selectedFile);

      // Request made to the backend api
      // Send formData object
        try {
          const request = await  fetch("/digitalpulse/upload", {
            method: "post",
            headers: {
                'accept': 'application/json' ,
                // 'Content-Type': 'multipart/form-data'
            },
            body:formData
          });

          const response = await request.json()
          console.log({response})
            if (response.status) {
                setStatus("SUCCESS")
                addToast(response.message, {
                appearance: 'success'
                })
              setSelectedFile(null)
               setStatus("IDLE")
            } else {
                setStatus("ERROR")
              addToast(response.message, {
                appearance: 'error'
              })
            }
        } catch (error) {
            setStatus("ERROR")
          addToast(error.message, {
                appearance: 'error'
              })
        }


    };
    return (
      <StyledMain className="main">
        <div className="upload__container">
        <div className="title__container">
        <h1 className="title__text">Upload your file</h1>
          <h2 className="subtitle__text">only txt file is allowed</h2>
          </div>
        <label htmlFor="nin_data" className="upload__container__label">
            <input type="file" accept=".txt" name="nin_data" id="nin_data" onChange={selectFile}
            disabled={status === "LOADING"}
            />
           {selectedFile ? <p className="input__container">{selectedFile.name}</p>:
            <p className="input__container">Click to select the file you want to upload</p>}
          </label>
        <button className="upload__button" onClick={onFileUpload}
          disabled={status === "LOADING" || !selectedFile}
        >
                {status === "LOADING" ? "Uploading" :
                    "Upload"}
          </button>
          </div>
        </StyledMain>
  )
}
