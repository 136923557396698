// store.js
import React, { createContext } from 'react';
import { useGlobalStates } from './useGlobalStates';

const initialState = {
    searchData: "",
    isDataLoading: "",
    smsData: null,
    infoText: "",
    isMailSending: false
};
const context = createContext(initialState);
const { Provider } = context;

const StateProvider = ({ children }) => {
    const store = useGlobalStates();


    return <Provider value = { store } > { children } </Provider>;
};

export { context, StateProvider }
