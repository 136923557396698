
import styled from 'styled-components'

export const StyledMain = styled.main`
    height: 100vh;
    width: 100vw;
    text-align: center;
    /* padding-top: 50px; */
    padding-right: 20px;
    padding-left: 20px;
    margin: 0 auto;

  .title {
   height: 25vh;
    display: flex;
    flex-direction:column;
    align-self: center;
    align-items: center;
    justify-content: flex-end;
font-style: normal;
font-weight: normal;
font-size: 34px;
color: rgba(0, 0, 0, 0.38);
  }

refresh{

}
.title{
/* margin-bottom:75px;   */
}
form{
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
      height: 25vh;
    align-items: center;
    flex-direction: column;
}
.form__input{
  width: 90%;
max-width: 600px;
height: 60px;
border:none;
background: #FFFFFF;
box-shadow: 0px 8px 16px #E0E2E4;
border-radius: 16px;
font-size: 21px;
padding: 0 34px;
color: rgba(0, 0, 0, 0.75072);
}
.form__search_button{
width: 153px;
height: 60px;
border: none;
background: #5B9D77;
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
border-radius: 15.5px;
font-style: normal;
font-weight: 500;
margin-top: 20px;
font-size: 23px;
line-height: 27px;
cursor:pointer;
:hover{
box-shadow: 0px 8px 16px #E0E2E4;
}
:active {
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
}

:disabled{
  opacity: .4;
}
/* identical to box height */


color: #FFFFFF;
}

@media screen and (min-width: 800px){
  /* margin-top: 256px */
}
`;

export const StyledResult = styled.div`

        height: 50vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 50px;

.result__title{
font-size: 23px;
line-height: 27px;
color: rgba(0, 0, 0, 0.75278);
/* padding: 76px 0; */
max-width: 400px;
margin:0 auto;
}
.result__action{
font-size: 21px;
color: #44353E;
border:none;
background: #FFFFFF;
border: 1.1px solid #00913E;
box-sizing: border-box;
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
border-radius: 15.5px;
padding: 15px 30px;
cursor:pointer;
:disabled{
  opacity: .4;
}
&.error {
  border: 1.1px solid red;
}

:hover{
box-shadow: 0px 8px 16px #E0E2E4;
}
:active {
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
}
}

`;
