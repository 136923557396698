import React from 'react'
import './App.css';
import {
  Switch,
  Route,
} from "react-router-dom";
import { Home, Auth, UploadNIN } from './pages';
import { ToastProvider } from 'react-toast-notifications';

const App = () => {
  return (
    <ToastProvider autoDismiss>
    <Switch>
      <Route path="/upload-nin" exact component={UploadNIN} />
      <Route path="/auth" exact component={Auth} />
      <Route path="/" component={Home} />
      </Switch>
      </ToastProvider>
  )
}

export default App;
