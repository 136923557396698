
import styled from 'styled-components'
import { ReactComponent as Refresh } from '../../refresh-page-option.svg'

export const StyledMain = styled.main`
      height: 100vh;
    width: 100vw;
    text-align: center;
    padding-right: 20px;
    padding-left: 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;

@media screen and (max-width: 768px){
 min-height: 100vh;
 height:unset;
    flex-direction: column-reverse;

    .search-container{
      flex: unset
    }
    .title {
      height: unset;
    }
    form{
      height: unset;
    }
}

    .search-container{
      flex: calc(100vh - 400px)
    }

  .title {
   height: 25vh;
    display: flex;
    align-self: center;
    align-items: flex-end;
    justify-content: center;
font-style: normal;
font-weight: normal;
font-size: 34px;
color: rgba(0, 0, 0, 0.38);
  }

refresh{

}
.title{
/* margin-bottom:75px;   */
}
form{
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
      height: 25vh;
    align-items: center;
}
.form__input{
  width: 90%;
max-width: 600px;
height: 60px;
border:none;
background: #FFFFFF;
box-shadow: 0px 8px 16px #E0E2E4;
border-radius: 16px;
font-size: 21px;
padding: 0 34px;
color: rgba(0, 0, 0, 0.75072);
}
.form__search_button{
width: 153px;
height: 60px;
border: none;
background: #5B9D77;
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
border-radius: 15.5px;
font-style: normal;
font-weight: 500;
font-size: 23px;
line-height: 27px;
cursor:pointer;
:hover{
box-shadow: 0px 8px 16px #E0E2E4;
}
:active {
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
}

:disabled{
  opacity: .4;
}
/* identical to box height */


color: #FFFFFF;
}

@media screen and (min-width: 800px){
  /* margin-top: 256px */
}
`;

export const StyledResult = styled.div`

        height: 50vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 50px;

.result__title{
font-size: 23px;
line-height: 27px;
color: rgba(0, 0, 0, 0.75278);
/* padding: 76px 0; */
max-width: 400px;
margin:0 auto;
}
.result__action{
font-size: 21px;
color: #44353E;
border:none;
background: #FFFFFF;
border: 1.1px solid #00913E;
box-sizing: border-box;
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
border-radius: 15.5px;
padding: 15px 30px;
cursor:pointer;
:disabled{
  opacity: .4;
}
&.error {
  border: 1.1px solid red;
}

:hover{
box-shadow: 0px 8px 16px #E0E2E4;
}
:active {
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
}
}
@media screen and (max-width: 768px){
   height: unset;
}
`;

export const RefreshIcon = styled(Refresh)``;

export const StyledDetails = styled.div`
 width: 100%;
      flex: 400px;
    padding: 50px 20px 0;
    box-shadow: 0px 8px 16px -6px rgb(10 31 68 / 8%);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;

    h1 {
      flex: 100px;
    display: grid;
    place-content: center;
    font-size: 25px;
    color: #4f5765;
    }

    .card {
    background: #FFFFFF;

    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 16px;
    margin: 24px auto;
    padding: 36px;
    width: 100%;
    box-shadow: rgb(10 31 68 / 8%) 0px 8px 16px -6px;
}

.card__item {
    display: flex;
    justify-content: space-between;
    padding: 6px;
    font-size: 18px;
    flex-direction: column;

      .error{

  }
  strong{
  width: unset;
  text-align: left;
  color: #9ea5b0;
  font-weight:normal;

  }

  p{
    text-align: left;
  align-self: flex-start;
  width: unset;
  color:#767c87;
  }
}

@media screen and (min-width: 768px){
    height: 100vh;
    overflow: scroll;

.display-data{
    overflow: scroll;
}

.card {
    overflow: hidden;
}


}

@media screen and (min-width: 1200px){
.card__item{
  flex-direction:row;
  gap:20px;
  strong{
  width: 150px;
  }

  p{
  width: calc(100% - 150px);
  }
}
}

.display-data {
 flex: calc(100vh - 100px);
}

.empty{
      height: 100%;
    display: grid;
    place-items: center;
    width: 70%;
   color:#767c87;
    text-align:center;
    margin:0 auto;
    font-size:26px;
}
`
