import React, { useState, useEffect } from 'react'
import {useHistory} from 'react-router-dom'
import {
  StyledMain,
} from './Auth.style.js'



export const Auth = () => {
   const [email, setEmail] = useState('')
    const history = useHistory()

    useEffect(() => {
        const handleUserLoggedIn = () => {
            let email = sessionStorage.getItem('email')
            const handlePush = () =>history.push('/')
            if (email) {
                setTimeout(handlePush, 2000)
            }
        }

        handleUserLoggedIn()
    }, [history])

  const handleChange = (e) => {
    setEmail(e.target.value)
  }

    const handleSubmit = (e) => {
        e.preventDefault()
        sessionStorage.setItem("email", JSON.stringify(email))
         history.push('/')
    }

  return (
    <StyledMain className="main">
          <div className='title'>
      <h1>Welcome,</h1>
      <h2>
Please enter your official email to access portal</h2>
      </div>

      <form className="form" onSubmit={handleSubmit}>
        <input type="email" value={email} name="email" className="form__input" onChange={handleChange}  required/>
        <button type="submit" className="form__search_button">Enter</button>
      </form>

    </StyledMain>
  );

}
