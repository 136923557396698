import { useState, useMemo,useEffect } from "react";
import {useHistory} from 'react-router-dom'
import { handlePhoneNumber } from '../util'

export const useGlobalStates = () => {
    const history = useHistory()
    //console.log(history)

    const [phoneNumber, setPhoneNumber] = useState('')
  const [searchData, setSearchData] = useState(null)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [error] = useState(null)
  const [smsData, setSmsData] = useState(null)
  const [infoText, setInfoText] = useState("")
  const [isMailSending, setIsMailSending] = useState(false)

    useEffect(() => {
        const handleUserLoggedIn = () => {
             let email = sessionStorage.getItem('email')
             if (!email) {
                 history.push('/auth')
             }
        }
        handleUserLoggedIn()
    }, )

         const handleChange = useMemo(() => (e) => {
    setSearchData(null)
    setInfoText('')
    setPhoneNumber(e.target.value)
  }, [setSearchData,
setInfoText,
setPhoneNumber]);

      const handleSearch = useMemo ( () => async (e) => {
    e.preventDefault()
    setSearchData(null)
    setInfoText("")
          setSmsData(null)
          setIsDataLoading(true)
    if (phoneNumber !== "" && handlePhoneNumber(phoneNumber)) {
      let sanitizedPhoneNumber = handlePhoneNumber(phoneNumber)


const url = `digitalpulse/search?phoneNumber=${sanitizedPhoneNumber}`
        try {
          const request = await fetch(url)
          const response = await request.json()
          if (Number(request.status) >= 200 && Number(request.status <= 299)) {
            let newData = response.data.data.sort((a, b) => (a['time-resp'] < b['time-resp']) ? 1 : -1)
           setSearchData(newData)
           setIsDataLoading(false)
          } else {
             setInfoText(response.message)
              setIsDataLoading(false)
          }
        } catch (error) {
           //console.log(error.message)
           setIsDataLoading(false)
           if (error.message.includes('Unexpected token')) {
        setInfoText("an error occurred")
      } else {
        setInfoText(error.message)
      }
      }
    } else {
        setInfoText("Please Cross Check the Phone Number")
        setIsDataLoading(false)
    }
      }, [phoneNumber,
          setSearchData,
setIsDataLoading,
setInfoText,])


   const handleParseData = useMemo(() => {
      if (searchData) {
        //console.log(searchData)
        //console.log(searchData.data)

let searchForNINError = searchData && searchData.find(request => {
          const { response } = request;
          return response.includes("No record found for NIN, Try again after 24 hours")
})

        let search = searchData && searchData.find(request => {
          const { response } = request;
          return (response.includes('The Profile Code is') || response.includes('Your Profile Code:') || response.includes('has already been registered with GSM Number')) && request["time-resp"].includes("2022-")
        })



        if (searchForNINError) {
          setSmsData({ isError: true, data: searchForNINError, type:"NIN_ERROR" })
          setInfoText("Profile code not found")
        }
        if (search) {
          setSmsData({ isError: false, data: search, type: "PROFILE_SUCCESS" })
          setInfoText("Profile code found")

        } else {
          setSmsData({ isError: true, type: 'PROFILE_ERROR' })
          setInfoText("Profile code not found")
        }
      }
    }, [setSmsData,searchData, setInfoText])

  const handleSendMessageToUser = useMemo(()=>async({msisdn:number, message}) => {
let URL = "/digitalpulse/sendSMS"
    let options = {
      method: "POST",
      headers: {
        "Content-type":"application/json"
      },
      body: JSON.stringify({
        sender: 55019,
        message,
        number
      })
    }
    try {
    const request = await fetch(URL, options)
    const response = await request.json()
      setIsMailSending(false)
      if (response.data.message === "Accepted for delivery") {
      setInfoText("Profile code sent successfully ")
      } else {
      setInfoText(response.data.message)
      }
    } catch (error) {
      setIsMailSending(false)
           if (error.message.includes('Unexpected token')) {
        setInfoText("an error occurred")
      } else {
        setInfoText(error.message)

      }
    }
  },[setIsMailSending,
setInfoText])


//   const handleSendMessageToOtherNetwork =useMemo(()=> async({msisdn:number, message, network}) => {
// let URL = "/digitalpulse/sendKannel"
//     let options = {
//       method: "POST",
//       headers: {
//         "Content-type": "application/json",
//         'Authorization': 'Bearer A5OKdJvjAohRTfbQ1pS6mL95sigqooQxTvV8ixhNP8w8nUrecmuRC8SczvG20oddeUZ3GmnqIw3Bq3sOpz5i6EnhuYYoi4Ti4j8j7hopQGYF05UBVhJHAD7uo8i5yLAd'
//       },
//       body: JSON.stringify({
//         message,
//         number,
//         network,
//       })
//     }
//     try {
//     const request = await fetch(URL, options)
//     const response = await request.json()
//       //console.log(response.data.status)
//       setIsMailSending(false)
//       setInfoText("Profile code sent successfully ")
//     } catch (error) {
//       setInfoText(error.message)
//       setIsMailSending(false)
//     }
//   },[setIsMailSending,
// setInfoText])


      const handleResendProfileCode = useMemo(()=> () => {
    setIsMailSending(true)
    setInfoText("")
    //console.log(smsData)
    if (Object.keys(smsData?.data).length > 0) {
      const {  msisdn, response:message } = smsData?.data
        handleSendMessageToUser({msisdn, message})
    }
  },[handleSendMessageToUser,  smsData])



    const uniqueUserId = () => {

    let email = JSON.parse(sessionStorage.getItem('email'))
    return (new Date().getTime() + "_" + email)
  }

  const handleSendEscalationMail = useMemo(()=> async (endpoint) => {
      let email = JSON.parse(sessionStorage.getItem('email'))
   const errorType =  smsData?.type === 'PROFILE_ERROR' ? "NIN ISSUE" : "OTHERS"

    let telegramBody = `
    <b>ESCALATED CASE</b>

<b>Number</b>: <b>${phoneNumber}</b>

<b>Reply To</b> : ${email}

<b>Tracking</b> : <i>${uniqueUserId()}</i>

<b>Type</b>: <b>${errorType}</b>

<b>Time Raised</b>: ${new Date().toGMTString()}
    `;

          let emailBody = `
    <h3>ESCALATED CASE</h3>

<p><b>Number</b>: <b>${phoneNumber}</b></p>

<p><b>Reply To</b> : ${email}</p>

<p><b>Tracking</b> : <i>${uniqueUserId()}</i></p>

<p><b>Type</b>: <b>${errorType}</b></p>

<p><b>Time Raised</b>: ${new Date().toGMTString()}</p>
    `;

// let emailBody = "testing"
    const URL = `/digitalpulse/${endpoint}`

//     let test = new URLSearchParams()
//     let options;
//     if(endpoint == "sendToTelegram"){
//       options =  {
//         method: 'POST',
//         headers: {
//           'Content-type': 'application/json'
//       },
//         body: JSON.stringify({emailBody: JSON.stringify(emailBody)})
//     }
//   } else {
//     options =  {
//       method: 'POST',
//       headers: {
//         'Content-type': 'application/json'
//     },
//       body: JSON.stringify({emailBody: emailBody})
//   }
//     }

      let message = endpoint === 'sendToTelegram' ? telegramBody:emailBody
    let options =  {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
    },
      body: endpoint !== 'sendToTelegram' ? JSON.stringify({emailBody}):  JSON.stringify({emailBody: JSON.stringify(message)})
}



setIsMailSending(true)
    try {
      const request =await fetch(URL, options)
      const response = await request.json()
      setIsMailSending(false)
      return response.message
    } catch (error) {
      setIsMailSending(false)
      //console.log(error.message)
      if (error.message.includes('Unexpected token')) {
        setInfoText("an error occurred")
      } else {
        setInfoText(error.message)

      }
    }
},[phoneNumber,smsData?.type ])

  const handleSendToTelegramAndMail =useMemo(()=> async () => {
const telegramResponse = await handleSendEscalationMail("sendToTelegram")
const mailResponse = await handleSendEscalationMail("sendEmail")

    if (telegramResponse === "Success" || mailResponse === "Success") {

      if (smsData?.type !== 'PROFILE_ERROR') {
        setInfoText(`Request successfully escalated to the technical partner. Please advise candidate to exercise patience and he/she will be contacted shortly`)
      } else {
             setInfoText(`Request successfully escalated to the technical partner. Please advise candidate to try again in 24 hours`)
      }
    }
},[handleSendEscalationMail,smsData?.type])


    return {
        isDataLoading,
error,
smsData,
infoText,
isMailSending,
handleChange,
handleSearch,
handleParseData,
handleResendProfileCode,
        handleSendToTelegramAndMail,
    searchData}
}
