export   const handlePhoneNumber = (number) => {
    if (number.length === 14 && number.startsWith('+234')) {
        return  number.substring(1)
    }
    if(number.length ===13 && number.startsWith('234')){
        return number
    }else if(number.length === 11 && (number.startsWith('08')
    || number.startsWith('09') || number.startsWith('07') )){
        let newNumber = '234' + number.substring(1)
        return newNumber
    }else if(number.length === 10 && (number.startsWith('8')
    || number.startsWith('9') || number.startsWith('7'))){
        let newNumber = '234' + number
        return newNumber
    }else return false
}
